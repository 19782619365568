import React from "react";
import { Helmet } from "react-helmet";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { useStaticQuery, graphql } from "gatsby";

import { PRODUCTION_SITE_URL } from "../constants/common";

const SEO = ({ isMainPage, meta }) => {
  const { datoCmsSite } = useStaticQuery(
    graphql`
      query LayoutQuery {
        datoCmsSite {
          globalSeo {
            fallbackSeo {
              title
              description
              twitterCard
              image {
                url
              }
            }
            twitterAccount
            titleSuffix
            siteName
            facebookPageUrl
          }
          faviconMetaTags {
            tags
          }
        }
      }
    `
  );

  const lang = "en";
  const type = "article";
  const metaTitle = (meta && meta.seo && meta.seo.title) || datoCmsSite.globalSeo.fallbackSeo.title;
  const metaDescription =
    (meta && meta.seo && meta.seo.description) || datoCmsSite.globalSeo.fallbackSeo.description;
  const metaImage =
    (meta && meta.seo && meta.seo.image && meta.seo.image.url) ||
    datoCmsSite.globalSeo.fallbackSeo.image.url;
  const url =
    isMainPage || !meta || !meta.path
      ? PRODUCTION_SITE_URL
      : `${PRODUCTION_SITE_URL}${
          meta.path[meta.path.length - 1] === "/"
            ? meta.path.slice(0, meta.path.length - 1)
            : meta.path
        }`;
  const twitterCard =
    (meta && meta.seo && meta.seo.twitterCard) || datoCmsSite.globalSeo.fallbackSeo.twitterCard;
  // const canonicalLink =
  //   meta && meta.articleSeo && meta.articleSeo.canonicalLink && meta.articleSeo.canonicalLink !== ""
  //     ? meta.articleSeo.canonicalLink
  //     : url;

  const ArticleSeoArr =
    meta && meta.articleSeo
      ? [
          {
            name: `article:publisher`,
            content: datoCmsSite.globalSeo.facebookPageUrl
          },
          // {
          //   name: `article:published_time`,
          //   content: meta.articleSeo.date
          // },
          // {
          //   name: `article:modified_time`,
          //   content: meta.articleSeo.date
          // },
          {
            name: `article:section`,
            content: meta.articleSeo.category
          },
          {
            name: `article:author`,
            content: meta.articleSeo.author
          }
        ]
      : [];

  return (
    <>
      <Helmet
        htmlAttributes={{ lang, prefix: "og: http://ogp.me/ns#" }}
        title={metaTitle}
        titleTemplate={isMainPage ? metaTitle : `%s ${datoCmsSite.globalSeo.titleSuffix}`}
        meta={[
          {
            name: `description`,
            content: metaDescription
          },
          {
            property: `og:locale`,
            content: "en_US"
          },
          {
            property: `og:title`,
            content: metaTitle
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: type
          },
          {
            property: `og:url`,
            content: url
          },
          {
            property: `og:site_name`,
            content: datoCmsSite.globalSeo.siteName
          },
          {
            property: `og:image:width`,
            content: "1920"
          },
          {
            property: `og:image:height`,
            content: "1080"
          },
          {
            property: `og:image`,
            content: metaImage
          },
          {
            property: `og:image:secure_url`,
            content: metaImage
          },
          {
            name: `twitter:title`,
            content: metaTitle
          },
          {
            name: `twitter:description`,
            content: metaDescription
          },
          {
            name: `twitter:card`,
            content: twitterCard
          },
          {
            name: `twitter:image`,
            content: metaImage
          }
        ].concat(ArticleSeoArr)}
      >
        {/* {meta && meta.articleSeo && <link rel="canonical" href={canonicalLink} />} */}

        {meta && meta.disableSearchIndexing && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>

      <HelmetDatoCms favicon={datoCmsSite.faviconMetaTags} />

    </>
  );
};

export default SEO;
